export default {
  siteTitle: 'Get bonus',
  blogerName: 'SKYZERA',
  socialsList: [
    {
      name: 'youtube',
      url: 'http://www.youtube.com/@skyzeraa'
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/skyzitoo?igshid=OGQ5ZDc2ODk2ZA=='
    },
    {
      name: 'telegram',
      url: 'https://t.me/vidadeslots'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://dripcasino.life/c50f59ded',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c43c669b7',
      gameTitle: 'Starda Queen Million (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c90cb9301',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c8a05a7e5',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cf5fac3da',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cf4f7c085',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cc12637a6',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>SKYZERA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'SKYZERA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado'
};
